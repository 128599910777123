import { Row, Col } from 'antd';
import PageSection from '../page-section';
import { Space } from "antd";
import Link from '../link';
import { Heading, Text } from '../typography';
import BCGovBadge from '../bc-gov-badge';
import BCcampusBadge from '../bccampus-badge';
import { faXTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import './index.css';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <footer>
      <PageSection className="bccampus-site-footer" type="dark">
        <Row gutter={[24, 24]}>
          <Col xs={24} md={12} xl={6} className="bccampus-site-footer-section bccampus-cms-content">
            <Heading level={3}>Support</Heading>
            <Space direction="vertical">
              <Link href="https://opentextbc.ca/accessibilitytoolkit/">Accessibility Toolkit</Link>
              <Link href="https://opentextbc.ca/adaptopentextbook/">Adaptation Guide</Link>
              <Link href="https://opentextbc.ca/adoptopentextbook/">Adoption Guide</Link>
              <Link href="https://opentextbc.ca/selfpublishguide/">Self-Publishing Guide</Link>
            </Space>
          </Col>

          <Col xs={24} md={12} xl={6} className="bccampus-site-footer-section bccampus-cms-content">
            <Heading level={3}>Contribute</Heading>
            <Space direction="vertical">
              <Link href="/report-error">Report an Error</Link>
              <Link href="/suggest-textbook">Suggest a Textbook</Link>
              <Link href="/review-textbook/">Become a Reviewer</Link>
              <Link href="/evaluate-oer/">Evaluate OER</Link>
              <Link href="https://open.bccampus.ca/use-open-textbooks/tell-us-youre-using-an-open-textbook/">Report Open Textbook Adoption</Link>
              <Link href="https://open.bccampus.ca/use-open-textbooks/tell-us-youre-using-open-course-material/">Report Open Course Materials Adoption</Link>
            </Space>
          </Col>
          <Col xs={24} md={12} xl={6} className="bccampus-site-footer-section">
            <Heading level={3}>Connect With Us</Heading>
            <Space wrap size="middle" style={{ marginBottom: "0.5em" }}>
              <Link type="icon" aria-label="Twitter" icon={faXTwitter} href="https://twitter.com/BCcampus/" />
              <Link type="icon" aria-label="LinkedIn" icon={faLinkedin} href="https://ca.linkedin.com/company/bccampus" />
              <Link type="icon" aria-label="Kaltura" icon={faPlayCircle} href="https://media.bccampus.ca/" />
            </Space>
            <Space direction="vertical" size={[14, 14]}>
              <Text>
                Get the latest information on news and events by subscribing to the BCcampus newsletter.
              </Text>
              <Link type="button" secondary href="https://bccampus.ca/subscribe/" style={{ width: "12em", textAlign: "center" }} >Subscribe</Link>
              <Link type="button" secondary href="/contact" style={{ width: "12em", textAlign: "center" }} >Contact Us</Link>
            </Space>
          </Col>
          <Col xs={24} md={12} xl={6} className="bccampus-site-footer-section">
            <BCGovBadge />
            <BCcampusBadge />
          </Col>
        </Row>
      </PageSection>
      <PageSection type="dark-alt" className='bccampus-site-footer-band'>
        <Row gutter={[24, 6]} className='bccampus-cms-content'>
          <Col xs={24}>
            <Text>
              <Link rel="license" href="http://creativecommons.org/licenses/by/4.0/">This site is licensed as CC-BY</Link> except where otherwise noted.
            </Text>
          </Col>
          <Col xs={24}>
            <Text>
              This site is protected by reCAPTCHA and the Google <Link href="https://policies.google.com/privacy">Privacy Policy</Link> and <Link href="https://policies.google.com/terms">Terms of Service</Link> apply.
            </Text>
          </Col>
        </Row>
      </PageSection >
    </footer >
  )
}

export default Footer;
